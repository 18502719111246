import {
  EndTD,
  ExpandLess,
  ExpandMore,
  IconButton,
} from "@kablamooss/geo-ds-core-components";
import { createColumnHelper } from "@tanstack/react-table";
import { ServiceProjectTypeAttribute } from "../../../.rest-hooks/types";
import { ProjectCategory, type Project } from "../../../types";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import {
  getFormattedDate,
  getFormattedDateAndTime,
} from "../../../utils/formatDate/formatDate";
import ProjectCodeLinkButton from "../../ui/ProjectCodeLinkButton/ProjectCodeLinkButton";

export type ProjectRow = Pick<
  Extract<Project, { type: "bfrmp" }>,
  | "activity"
  | "bfmcs"
  | "createdAt"
  | "currentStage"
  | "id"
  | "lastActivityDateTime"
  | "name"
  | "owner"
  | "status"
>;

const columnHelper = createColumnHelper<ProjectRow>();

export const columns = [
  columnHelper.accessor("name", {
    enableSorting: true,
    header: "Project code",
    cell: (props) => {
      const projectCode = props.cell.getValue();
      const projectId = props.row.original.id;

      if (!projectCode || !projectId) {
        return <FallbackElement />;
      }

      return (
        <ProjectCodeLinkButton
          projectCode={projectCode}
          projectId={projectId}
          projectCategory={ProjectCategory.BFRMP}
          projectType={ServiceProjectTypeAttribute.bfrmp}
        />
      );
    },
  }),
  columnHelper.accessor("bfmcs", {
    enableSorting: false,
    header: "BFMC",
    size: 200,
    cell: (props) => {
      const value = props.cell.getValue();
      if (!value) {
        return <FallbackElement />;
      }
      const bfmcNames = value.map((bfmcs) => bfmcs.name);
      return bfmcNames.join(", ");
    },
  }),
  columnHelper.accessor("status", {
    enableSorting: true,
    header: "Project status",
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }

      return value;
    },
  }),
  columnHelper.accessor("createdAt", {
    enableSorting: true,
    header: "Created",
    cell: (props) => {
      const value = props.cell.getValue();
      if (!value) {
        return <FallbackElement />;
      }
      return getFormattedDate(value);
    },
  }),
  columnHelper.accessor("currentStage", {
    enableSorting: false,
    header: "Scenario",
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }

      return value;
    },
  }),
  columnHelper.accessor("activity", {
    enableSorting: false,
    header: "Activity",
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }

      return value;
    },
  }),
  columnHelper.accessor("lastActivityDateTime", {
    enableSorting: true,
    header: "Last updated",
    cell: (props) => {
      const value = props.cell.getValue();
      if (!value) {
        return <FallbackElement />;
      }
      return getFormattedDateAndTime(value);
    },
  }),
  columnHelper.display({
    enableSorting: false,
    header: "",
    id: "expander",
    cell: (props) => {
      const isExpanded = props.row.getIsExpanded();

      return (
        <EndTD>
          <IconButton
            icon={isExpanded ? ExpandLess : ExpandMore}
            label={isExpanded ? "Collapse" : "Expand"}
            onClick={() => props.row.toggleExpanded(!isExpanded)}
            size="sm"
            variant="secondary"
          />
        </EndTD>
      );
    },
  }),
];
