import {
  NavSubheader,
  type NavSubheaderProps,
} from "@kablamooss/geo-ds-core-components";
import { styled } from "styled-components";
import useIsOnline from "../../../hooks/useIsOnline";
import OfflineIndicator from "../OfflineIndicator/OfflineIndicator";

const StyledContent = styled.div`
  flex: 1;
  height: 2.5rem;
`;

interface AppNavSubheaderProps extends NavSubheaderProps {
  hasSubheaderContent?: boolean;
}

const AppNavSubheader = ({
  children,
  hasSubheaderContent = true,
  ...props
}: AppNavSubheaderProps) => {
  const isOnline = useIsOnline();

  return (
    <>
      {hasSubheaderContent && (
        <NavSubheader {...props}>
          <StyledContent>{children}</StyledContent>
        </NavSubheader>
      )}
      {!isOnline && <OfflineIndicator data-testid="offline-indicator" />}
    </>
  );
};

export default AppNavSubheader;
