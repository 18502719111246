import {
  Info,
  Text,
  TypographyIconWrapper,
} from "@kablamooss/geo-ds-core-components";
import styled from "styled-components";

const StyledOfflineIndicator = styled.div`
  display: flex;
  padding: 0.75rem 1.5rem calc(0.75rem - 1px) 1.5rem;
  align-items: center;
  gap: 0.5rem;
  z-index: ${(p) => p.theme.zIndexes.appHeader};
  overflow-x: auto;
  white-space: nowrap;
  background-color: ${(p) => p.theme.colors.warning.backgroundWeak};
  color: ${(p) => p.theme.colors.warning.text};
`;

type OfflineIndicatorProps = {
  "data-testid"?: string;
};

const OfflineIndicator = ({
  "data-testid": dataTestId,
}: OfflineIndicatorProps) => {
  return (
    <StyledOfflineIndicator data-testid={dataTestId}>
      <TypographyIconWrapper icon={Info} size="label" variant="warning" />
      <Text data-testid={dataTestId && `${dataTestId}-message`} size="label">
        You are offline due to an unstable connection. Check your network
        settings.
      </Text>
    </StyledOfflineIndicator>
  );
};

export default OfflineIndicator;
