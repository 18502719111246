import { EndTD } from "@kablamooss/geo-ds-core-components";
import { createColumnHelper } from "@tanstack/react-table";
import styled from "styled-components";
import type { Run } from "../../../types";
import FallbackElement from "../../../utils/fallBackElement/fallBackElement";
import { getFormattedDateAndTime } from "../../../utils/formatDate/formatDate";
import AcceptScenarioRun from "../../scenarios/AcceptScenarioRun/AcceptScenarioRun";
import ScenarioRunOverflowMenu from "../../scenarios/ScenarioRunOverflowMenu/ScenarioRunOverflowMenu";
import RunCodeLinkButton from "../../ui/RunCodeLinkButton/RunCodeLinkButton";

const StyledScenarioRun = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
`;

const columnHelper = createColumnHelper<Run>();

export const subColumns = [
  columnHelper.accessor("name", {
    enableSorting: false,
    header: "Run",
    cell: (props) => {
      const value = props.cell.getValue();
      const run = props.row.original;
      const { id: runId, project, scenario } = run;

      if (!value) {
        return <FallbackElement />;
      }

      return (
        <StyledScenarioRun>
          <AcceptScenarioRun run={run} />
          <RunCodeLinkButton
            href={`/project/${project.id}/scenarios/${runId}/inputs/general`}
            projectId={project.id}
            scenario={scenario}
          >
            {value}
          </RunCodeLinkButton>
        </StyledScenarioRun>
      );
    },
  }),
  columnHelper.accessor("description", {
    enableSorting: false,
    header: "Description",
    size: 240,
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }

      return value;
    },
  }),
  // Yes, this is confusing. BE has both activity and status fields and status is used for activity.
  columnHelper.accessor("status", {
    enableSorting: false,
    header: "Activity",
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }

      return value;
    },
  }),
  columnHelper.accessor("completedAt", {
    enableSorting: false,
    header: "Completed at",
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }
      return getFormattedDateAndTime(value);
    },
  }),
  columnHelper.accessor("acceptedBy", {
    enableSorting: false,
    header: "Accepted by",
    cell: (props) => {
      const value = props.cell.getValue();
      const acceptedBy = value?.name;

      if (!acceptedBy) {
        return <FallbackElement />;
      }

      return acceptedBy;
    },
  }),
  columnHelper.accessor("acceptedAt", {
    enableSorting: false,
    header: "Accepted at",
    cell: (props) => {
      const value = props.cell.getValue();

      if (!value) {
        return <FallbackElement />;
      }
      return getFormattedDateAndTime(value);
    },
  }),
  columnHelper.display({
    enableSorting: false,
    header: "",
    id: "actions",
    cell: (props) => {
      const run = props.row.original;

      return (
        <EndTD>
          <ScenarioRunOverflowMenu
            parentHref="/project"
            projectId={run.project.id}
            projectType={run.project.type}
            run={run}
          />
        </EndTD>
      );
    },
  }),
];
