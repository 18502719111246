import maxBy from "lodash/maxBy";
import type { AppRunScenario } from "../../../.rest-hooks/types";
import type { Run } from "../../../types";

export const getImpactAnalysisScenarioRun = (
  scenario: AppRunScenario,
  runs: readonly Run[],
): Run | undefined => {
  const scenarioRuns = runs.filter(
    (run) => run.scenario === scenario && run.type === "PhoenixPostprocessing",
  );
  if (!scenarioRuns.length) {
    return;
  }

  const acceptedRun = scenarioRuns.find((run) => run.accepted === true);
  if (acceptedRun) {
    return acceptedRun;
  }

  const completedRuns = scenarioRuns.filter((run) => run.status === "Complete");
  if (!!completedRuns && completedRuns.length) {
    return maxBy(completedRuns, "completedAt");
  }

  return maxBy(scenarioRuns, "createdAt");
};

export const getBfrmpProjectScenarioRuns = (runs: readonly Run[]): Run[] => {
  const currentRisk = getImpactAnalysisScenarioRun("CR", runs);
  const futureRisk = getImpactAnalysisScenarioRun("FR", runs);
  const futureRiskWithTreatment = getImpactAnalysisScenarioRun("FT", runs);

  const currentRiskRuns = runs.filter((run) => run.scenario === "CR");
  const futureRiskRuns = runs.filter((run) => run.scenario === "FR");

  const currentRiskHasAcceptedRun = currentRiskRuns.find(
    (run) => run.accepted === true,
  );
  const futureRiskHasAcceptedRun = futureRiskRuns.find(
    (run) => run.accepted === true,
  );

  return [
    ...(currentRisk ? [currentRisk] : []),
    ...(futureRisk && currentRiskHasAcceptedRun ? [futureRisk] : []),
    ...(futureRiskWithTreatment && futureRiskHasAcceptedRun
      ? [futureRiskWithTreatment]
      : []),
  ];
};
