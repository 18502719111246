import { DataTable } from "@kablamooss/geo-ds-core-components";
import Link from "next/link";
import styled from "styled-components";
import type { Run } from "../../../types";
import PlaceholderCard from "../../ui/PlaceholderCard/PlaceholderCard";
import { subColumns } from "./subColumns";
import { getBfrmpProjectScenarioRuns } from "./utils";

const StyledProjectsSubDataTable = styled.div`
  padding: 0.5rem;
  background-color: ${(p) => p.theme.colors.neutrals.backgroundMedium};
`;

interface ProjectsSubDataTableProps {
  runs: readonly Run[];
}

const ProjectsSubDataTable = ({ runs }: ProjectsSubDataTableProps) => {
  const tableData = getBfrmpProjectScenarioRuns(runs);

  return (
    <StyledProjectsSubDataTable>
      {tableData.length ? (
        <DataTable
          clickableRows
          columns={subColumns}
          data={tableData}
          data-testid="project-sub-table"
          getRowId={(row) => row.id}
          page={1}
          perPage={10}
          renderRow={(row, rowElement) => {
            const projectId = row.original.project.id;
            const runId = row.original.id;

            return (
              <Link
                href={`/project/${projectId}/scenarios/${runId}/inputs/general`}
              >
                {rowElement}
              </Link>
            );
          }}
        />
      ) : (
        <PlaceholderCard
          title="No active runs"
          subtitle="There are no runs available yet"
          status="empty"
          withoutBorder
        />
      )}
    </StyledProjectsSubDataTable>
  );
};

export default ProjectsSubDataTable;
