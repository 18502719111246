import Link from "next/link";
import type { AppRunScenario } from "../../../.rest-hooks/types";
import { useHasProjectPrivilege } from "../../../hooks/useHasProjectPrivilege";
import RunTag from "../RunTag/RunTag";

interface RunCodeLinkButtonProps {
  children: React.ReactNode;
  href: string;
  projectId: string;
  scenario: AppRunScenario;
}

const RunCodeLinkButton = ({
  children,
  href,
  projectId,
  scenario,
}: RunCodeLinkButtonProps) => {
  const { data: hasReadPrivilege } = useHasProjectPrivilege(projectId, {
    requestedPermissions: ["view-runs", "view-run-inputs"],
  });

  if (hasReadPrivilege) {
    return (
      <Link href={href}>
        <RunTag variant={scenario}>{children}</RunTag>
      </Link>
    );
  }

  return <RunTag variant={scenario}>{children}</RunTag>;
};

export default RunCodeLinkButton;
